import logo from '../LogoWise-Sales.png';
import '../App.css';
import { useState } from 'react';
import Lottie from 'react-lottie';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Button, TextField, DialogContent, Box } from '@mui/material';
import scraper from '../scraper.json';


const Login = () => {
  const [pageState, setPageState] = useState("Login");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    loginResponseMessage: "",
  });
  const [registerData, setRegisterData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    registerResponseMessage: "",
  });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: scraper,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const registerPageChange = () => {
    setPageState("Register");
    setRegisterData({
      email: "",
      password: "",
      confirmPassword: "",
      registerResponseMessage: "",
    });
  };

  const loginPageChange = () => {
    setPageState("Login");
    setLoginData({
      email: "",
      password: "",
      loginResponseMessage: "",
    });
  };

  const handleLogin = async () => {
    if(registerData.password === registerData.confirmPassword) {
      try{
        const loginResponse = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/login`, loginData);
        if(loginResponse.status === 200 && loginResponse.data.access_token) {
          localStorage.setItem("Token", loginResponse.data.access_token);
          window.location.assign("/app");
        }
      } catch (error: any) {
        setLoginData({ ...loginData, loginResponseMessage: error.response.data.message });
        setRegisterData({ ...registerData, registerResponseMessage: "" })
      }
    } else {
      setRegisterData({ ...registerData, registerResponseMessage: "Passwords don't match" })
    }
  };

  const handleRegister = async () => {
    try {
      const registerResponse = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/register`, registerData);
      if(registerResponse.status === 201 && registerResponse.data.message === "User registered successfully") {
        setPageState("Login");
        setLoginData({
          email: "",
          password: "",
          loginResponseMessage: "",
        });
        setRegisterData({
          ...registerData,
          registerResponseMessage: `${registerResponse.data.message}. Kindly Login to continue`,
        });
      }
    } catch (e: any) {
      setRegisterData({ ...registerData, registerResponseMessage: e.response.data.message })
    }
  };

  return (
      <Box style={{ display: 'flex', flexDirection: 'row', height: '100vh', overflow: 'auto' }}>
        <Box style={{ width: '60%', display:'flex', alignItems:'center'}}>
          <Typography variant='h4' style={{fontFamily:'Orbitron',fontWeight:'medium',color:'white', position:'absolute', top:'50px', left:'40px'}}>
            WISE SCRAPER
          </Typography>
          <Lottie options={defaultOptions} height={400} width={300} />
        </Box>
        <Box style={{ padding: '0px 40px', alignItems: 'center', width: '40%', backgroundColor: '#F4F7FF', height: '100%', overflow: 'auto' }}>
          <Box>
            <img src={logo} alt="logo" style={{maxWidth:'30%'}} />
            <Typography>{registerData.registerResponseMessage}</Typography>
            <Typography>{loginData.loginResponseMessage}</Typography>
          </Box>
          {pageState === "Login" && (
              <Box>
                <Typography variant='h3' style={{fontFamily:'Orbitron', marginTop:'20px', color:'#0C1B4D'}}>
                  Login
                </Typography>
                <Typography style={{fontSize:'20px',padding:'20px 0px', color:'#0C1B4D'}}>
                  Enter your credentials to access Wise Scraper to increase your quote generation, target specific materials and obtain qualified prospects.
                </Typography>


                <Typography style={{fontFamily:'Orbitron', marginTop:'30px'}}> Email </Typography>
                <TextField
                    id="email"
                    type="text"
                    placeholder="Enter your Email Id"
                    value={loginData.email}
                    onChange={(e) => setLoginData({ ...loginData, email: e.target.value })}
                    fullWidth
                    style={{backgroundColor:'#F9F9F9'}}
                />
                <Typography style={{fontFamily:'Orbitron', marginTop:'20px'}}> Password </Typography>
                <TextField
                    id="password"
                    type="password"
                    placeholder="Enter your Password"
                    value={loginData.password}
                    onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                    fullWidth
                    style={{backgroundColor:'#F9F9F9'}}
                />
                <Button variant="contained" onClick={handleLogin} style={{width:'100%', marginTop:'20px'}}>Login</Button>
                <Box style={{display:'flex', marginTop:'15px'}}>
                  <Typography> New User? </Typography>
                  <Typography
                      onClick={registerPageChange}
                      style={{
                        cursor: 'pointer',
                        padding: '0px 15px',
                        textDecoration: 'underline',
                        color: isHovered ? 'red' : '#356F9F',
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                  >
                    Register
                  </Typography>
                </Box>

              </Box>
          )}
          {pageState === "Register" && (
              <Box>
                <Typography variant='h3' style={{fontFamily:'Orbitron', marginTop:'20px', color:'#0C1B4D'}}>
                  Register
                </Typography>
                <Typography style={{fontSize:'20px',padding:'20px 0px', color:'#0C1B4D'}}>
                  Register to join Wise Scrape and scrape for possible qualified prospects to increase your quote generation.
                </Typography>


                <Typography style={{fontFamily:'Orbitron', marginTop:'30px'}}> Email </Typography>
                <TextField
                    id="email"
                    type="text"
                    placeholder="Enter your Email"
                    value={registerData.email}
                    onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
                    fullWidth
                    style={{backgroundColor:'#F9F9F9'}}
                />
                <Typography style={{fontFamily:'Orbitron', marginTop:'30px'}}> Password </Typography>
                <TextField
                    id="password"
                    type="password"
                    placeholder="Enter your Password"
                    value={registerData.password}
                    onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
                    fullWidth
                    style={{backgroundColor:'#F9F9F9'}}
                />
                <Typography style={{fontFamily:'Orbitron', marginTop:'30px'}}> Confirm Password </Typography>

                <TextField
                    id="confirmPassword"
                    type="password"
                    placeholder="Confirm your Password"
                    value={registerData.confirmPassword}
                    onChange={(e) => setRegisterData({ ...registerData, confirmPassword: e.target.value })}
                    fullWidth
                    style={{backgroundColor:'#F9F9F9'}}

                />
                <Button variant="contained" onClick={handleRegister} style={{width:'100%', marginTop:'20px'}}>Register</Button>
                <Box style={{display:'flex', marginTop:'15px'}}>
                  <Typography> Already a part of Wise Scraper?</Typography>
                  <Typography
                      onClick={loginPageChange}
                      style={{
                        cursor: 'pointer',
                        padding: '0px 15px',
                        textDecoration: 'underline',
                        color: isHovered ? 'red' : '#356F9F',
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                  >
                    Login
                  </Typography>

                </Box>

              </Box>
          )}
        </Box>
      </Box>
  );

};


export default Login;
