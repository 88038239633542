import React from 'react';
// import logo from './LogoWise-Sales.png';
import './App.css';
// import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loading from './Loading';
import Scrapper from './Pages/Scrapper';
import Login from './Pages/Login';
import {ThemeProvider, createTheme } from '@mui/material';


const theme = createTheme({
    palette: {
        primary: {
            main: '#0C1B4D', // Example primary color
        },
        secondary: {
            main: '#080808', // Example secondary color
        },

    },
});
function Main() {
    return (
        <ThemeProvider theme={theme}>
            <Router>

                    <Routes>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/loading" element={<Loading/>}/>
                        <Route path="/app" element={<Scrapper/>}/>
                    </Routes>
            </Router>
        </ThemeProvider>
    );
}
export default Main;