import React, { useState, useEffect } from 'react';
import logo from './LogoWise-Sales.png'; // Import your logo
import io from 'socket.io-client'; // Import socket.io-client
import './Loading.css';

const LoadingPage: React.FC = () => {
    const [downloadReady, setDownloadReady] = useState<boolean>(false);
    const [hovered, setHovered] = useState<boolean>(false);
    const [downloadCompleted, setDownloadCompleted] = useState<boolean>(false);

    useEffect(() => {
        // Establish a WebSocket connection with the backend
        const socket = io('https://scrapper-api.wise-sales.com/scrapper');

        // Listen for 'results_ready' event from the backend
        socket.on('results_ready', () => {
            setDownloadReady(true);
        });

        return () => {
            // Disconnect the WebSocket connection when component unmounts
            socket.disconnect();
        };
    }, []);

    const handleDownload = async () => {
        try {
            // Send a request to download the results
            window.location.href = 'https://scrapper-api.wise-sales.com/download-results';
            setDownloadCompleted(true); // Set download completed state to true
        } catch (error) {
            console.error('Error downloading results:', error);
        }
    };

    const handleProceed = () => {
        // Handle proceeding to the home page
        // For example, you can use React Router to navigate to the home page
        // Replace the example URL with the actual URL of your home page
        window.location.href = '/';
    };

    return (
        <div className="loading-container">
            {/* Place your logo in the middle */}
            <img src={logo} className="loading-logo" alt="logo" />
            {/* Add loading animation or message */}
            <div className="loading-animation"></div>
            {/* Display download button if download is ready */}
            {downloadReady && (
                <div className="button-container">
                    <button
                        className="download-button"
                        onClick={handleDownload}
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                        style={{ backgroundColor: hovered ? '#356F9F' : '#3498db' }}
                    >
                        Download Results
                    </button>
                </div>
            )}
            {/* Render popup if download is completed */}
            {downloadCompleted && (
                <div className="popup">
                    <p>Congrats! Your scrape is done.</p>
                    <button className="proceed-button" onClick={handleProceed}>
                        Proceed to Home Page
                    </button>
                </div>
            )}
        </div>
    );
};

export default LoadingPage;