import React, { useState, ChangeEvent, useEffect } from 'react';
import logo from '../LogoWise-Sales.png';
import '../App.css';
import axios from 'axios';
import { AppBar, Toolbar, Typography, Button, TextField, DialogContent, Box, Input } from '@mui/material';


function Scrapper() {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [areResultsReady, setAreResultsReady] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string[]>([]);


    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
          // Check if the file type is not CSV

          if (file.type !== 'text/csv' &&
              !['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(file.type))
          {
              alert('Please upload a CSV or Excel file.');
              return; // Exit the function early if the file is not a CSV
          }
          // Determine which input is being used and set the appropriate state
          if (event.target.id === 'csvFileInput') {
              setCsvFile(file);
          }
      }
  };

    const handleKeywordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const keywordsArray = event.target.value.split(',').map(keyword => keyword.trim());
        setKeywords(keywordsArray);
    };

  const checkTokenValidity = async () => {
    try {
      const token = localStorage.getItem("Token")
      const validityResponse = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/validate_token`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      if(validityResponse.status === 200 && validityResponse.data.user) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if(!localStorage.getItem("Token")) {
      window.location.assign("/");
    } else {
      checkTokenValidity().then(data => {
        if(!data) {
          localStorage.removeItem("Token");
          window.location.assign("/");
        }
      });
    }
  })

  const axiosInstance = axios.create({
      timeout: 900000,
  });
  const handleSubmit = async () => {
      if (csvFile && keywords) {
          setUploading(true);
          try {
              const formData = new FormData();
              formData.append('csvFile', csvFile); // Change the field name to distinguish between CSV file and keywords CSV file
              formData.append('keywords', keywords.join(',')); // Convert keywords array to comma-separated string and add to form data
              const currentDateObj = new Date()
              const month = (currentDateObj.getMonth() > 10) ? `${currentDateObj.getMonth() + 1}` : `0${currentDateObj.getMonth() + 1}`
              const currentTimestamp = `${month}${currentDateObj.getDate()}${currentDateObj.getFullYear()}_${currentDateObj.getHours()}${currentDateObj.getMinutes()}${currentDateObj.getSeconds()}` // Creating the timestamp from the Date object
              localStorage.setItem("Timestamp", currentTimestamp);
              formData.append('timestamp', currentTimestamp)
              const token = localStorage.getItem("Token")
              await axiosInstance.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/upload-files`, formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${token}`
                  },
              });
              setUploading(false);
              setAreResultsReady(true);
          } catch (error) {
              console.error('Error:', error);
          } finally {
              setUploading(false);
          }
      } else {
          console.warn('Both CSV and keywords files must be selected');
      }
  };
  const handleDownloadResults = async () => {
      try {
          const token = localStorage.getItem("Token");
          const currentTimestamp = localStorage.getItem("Timestamp")
          const results = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/download-results`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Timestamp': currentTimestamp
            }
          });
          // Create a blob object from the response data
          const blob = new Blob([results.data]);
          // Create a temporary anchor element to trigger the download
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = 'results.csv'; // Specify the file name here
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
      } catch(error) {}
  };
    return (
        <Box className="App">
            <Box className="App-header">
                {!areResultsReady && !uploading && (
                    <Box style={{backgroundColor:'white', padding:'60px', borderRadius:'20px'}}>
                        <Box>
                            <Typography variant="h4" style={{color:'#0C1B4D', fontFamily:'Orbitron', fontWeight:'medium'}}> WISE SCRAPER</Typography>
                            <img src={logo} alt="logo" style={{maxWidth:'30%'}} />
                        </Box>
                        <Typography style={{color:'#0C1B4D', marginTop:'30px'}}>Choose a CSV or Excel file with your companies<br/> and websites you want to scrape</Typography>
                        <Input
                            id="csvFileInput"
                            type="file"
                            onChange={handleFileUpload}
                            style={{color:'#0C1B4D', display: 'none'}}
                            inputProps={{
                                accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' // Accept CSV, Excel 2007+, and Excel files
                            }}
                        />
                        <label htmlFor="csvFileInput">
                            <Button
                                variant="contained"
                                component="span"
                                style={{ marginTop: '10px' }}
                            >
                                Choose File
                            </Button>
                        </label>
                        {csvFile && <Typography style={{color:'#0C1B4D'}}>Uploaded file: {csvFile.name}</Typography>}
                        <Typography style={{color:'#0C1B4D', marginTop:'40px'}}>Enter your keywords separated by commas</Typography>
                        <TextField
                            id="keywordsInput"
                            multiline
                            rows={4} // Adjust the number of rows as needed
                            onChange={handleKeywordChange}
                            style={{color:'#0C1B4D', marginTop: '10px', width:'80%'}}
                            inputProps={{
                                maxLength: 1000
                            }}

                        />
                        <br />
                        <Button
                            variant="contained"
                            className="upload-button"
                            onClick={handleSubmit}
                            disabled={uploading}
                            style={{marginTop:'30px'}}
                        >
                            {uploading ? 'Uploading...' : 'Upload CSV and Keywords'}
                        </Button>
                    </Box>
                )}
                {!areResultsReady && uploading && (
                    <Box>
                        <Box>
                            <img src={logo} alt="logo" style={{maxWidth:'30%'}} />
                        </Box>
                        <Typography variant='h4' style={{fontFamily:'Orbitron'}}>Loading....</Typography>
                    </Box>
                )}
                {areResultsReady && !uploading && (
                    <Box style={{backgroundColor:'white', padding:'40px', borderRadius:'20px'}}>
                        <Box>
                            <img src={logo} alt="logo" style={{maxWidth:'30%'}} />
                            <Typography variant='h6' style={{color:'#0C1B4D', fontFamily:'Orbitron', fontWeight:'medium'}}> Congratulations! Your Results are ready!</Typography>
                        </Box>
                        <Button variant="contained" className="upload-button" onClick={handleDownloadResults} style={{marginTop:'40px', fontFamily:'Orbitron'}}>
                            Download Results
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default Scrapper;